import React, {
  useCallback,
  Fragment
} from 'react'
import {
  domAnimation,
  LazyMotion,
  m
} from 'framer-motion'

// Components
import Grid, { Col } from '@/components/meta/Grid'
import CardProject from '@/components/molecules/CardProject'
import CtaRowCompact from '@/components/molecules/CtaRowCompact'
import Icon from '@/components/atoms/Icon'

// Styling
import styles from './SectionProjects.module.scss'

// Helpers
import { useMediaQuery } from '@/hooks'

// Types
import { IconRotate, IconSize } from '@/lib/enums'
import type * as Services from '@/lib/api/queries/services/__generated__/ServicesQuery'

type ProjectCard = Omit<Project, 'header'
  | 'isFeatured'
  | 'companyName'
  | 'companyUrl'
  | 'companyLogo'
  | 'list'
  | 'introTitle'
  | 'introDescription'
  | 'content'
  | 'cta'>

export interface SectionProjectsProps {
  title?: string | null
  description?: string
  projects?: ProjectCard[]
  innerComponent?: React.ReactNode
  hideCta: boolean
  amountOfItems?: number
  loadMore?: () => void
  maxItems: number
  isSubSection?: boolean
}

const SectionProjects: React.FC<SectionProjectsProps> = ({
  title,
  description,
  projects,
  hideCta,
  innerComponent,
  amountOfItems,
  loadMore,
  maxItems,
  isSubSection
}) => {
  const isMobile = useMediaQuery('(max-width: 768px)')

  const determineSize = useCallback((size: string) => {
    switch (size) {
      case 'small':
        return styles.small
      case 'large':
        return styles.large
      case 'medium':
      default:
        return styles.medium
    }
  }, [])

  const determineStart = useCallback((size: string, isEven: boolean) => {
    if (!isEven && !isMobile) {
      return styles.right
    }

    switch (size) {
      case 'small':
        return styles.end
      case 'large':
        return styles.start
      case 'medium':
      default:
        return styles.middle
    }
  }, [isMobile])

  const Component = isSubSection ? 'h3' : 'h2'

  return (
    <section className={styles['section-projects']}>
      { title && description && (
        <Grid className={styles.header} cols={12} size="l">
          <Col start={2}>
            <Component className={`heading-3-xl ${styles.title}`}>{title}</Component>
            <p className={`body-l ${styles.subtitle}`}>
              {description}

              <Icon
                className={styles.icon}
                color="var(--sweet-liquorice-900)"
                direction={IconRotate.South}
                name="arrow"
                size={IconSize.Large}
              />
            </p>
          </Col>
        </Grid>
      )}

      <Grid className={styles.content} cols={12} size="l">
        <LazyMotion features={domAnimation}>
          {projects?.map((project, idx) => {
            const defaultStart = idx % 2 === 0
            const innerStart = idx === 0 ? idx % 2 === 0 : idx % 2 !== 0

            return (
              <Fragment key={idx}>
                {idx === 1 && innerComponent && (
                  <Col className={styles.inner} span={5}>
                    {innerComponent}
                  </Col>
                )}

                <Col
                  // @ts-expect-error This is because of the inline animation
                  animate={
                    amountOfItems
                      ? idx < amountOfItems
                    && {
                      y: 0,
                      opacity: 1,
                      display: 'block'
                    }
                      : {
                        y: 0,
                        opacity: 1,
                        display: 'block'
                      }}
                  as={m.div}
                  className={`
                      ${styles.card}
                      ${determineSize(project.thumbnailSize)}
                      ${determineStart(project.thumbnailSize, innerComponent ? innerStart : defaultStart)}
                    `}
                  initial={{
                    y: 32,
                    opacity: 0,
                    display: 'none'
                  }}
                  key={`${idx}-${project.title}`}
                  transition={{
                    duration: 0.5,
                    ease: 'easeOut'
                  }}
                >
                  <CardProject
                    categories={project.categories as Services.ServicesQuery_pages_overviewProjects_categories[]}
                    description={project.cardDescription}
                    keepAspectRatio={false}
                    slug={project.slug}
                    // @ts-expect-error This is because of the type generation and missing property '__typename'
                    thumbnail={project.thumbnail}
                    thumbnailColor={project.thumbnailColor}
                    title={project.title}
                  />
                </Col>
              </Fragment>
            )
          })}
        </LazyMotion>
      </Grid>

      {(!hideCta && (amountOfItems ?? -1) < maxItems) && (
        <Grid className={styles.cta} cols={12} size="l">
          <Col>
            <CtaRowCompact
              buttonText="View more projects"
              onClick={loadMore}
              title="Wait, there's more!"
            />
          </Col>
        </Grid>
      )}
    </section>
  )
}

export default SectionProjects
