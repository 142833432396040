import React, {
  useState,
  useEffect
} from 'react'
import Link from 'next/link'
import {
  LazyMotion,
  domAnimation,
  m,
  motion,
  useCycle,
  AnimatePresence
} from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import { useMediaQuery } from '@/hooks'

// Styling
import styles from './ServicesBlock.module.scss'

// Components
import Grid, { Col } from '@/components/meta/Grid'
import Icon from '@/components/atoms/Icon'
import ButtonLink from '@/components/atoms/ButtonLink'

// Lib
import type { ServicesQuery_pages } from '@/lib/api/queries/services/__generated__/ServicesQuery'

// Types
export interface ServicesBlockProps {
  services: ServicesQuery_pages[]
}
import { IconRotate, IconSize } from '@/lib/enums'

const variants = {
  shown: {
    opacity: 1,
    display: 'block',
    y: 0
  },
  hidden: {
    opacity: 0,
    display: 'none',
    y: 50
  }
}

const variantsReducedMotion = {
  shown: {
    opacity: 1,
    display: 'block'
  },
  hidden: {
    opacity: 0,
    display: 'none'
  }
}

const variantsHiddenText = {
  shown: {
    opacity: 1,
    height: 'auto'
  },
  hidden: {
    opacity: 0,
    height: '0px'
  }
}

const variantsMobileIllu = {
  shown: {
    height: '272px',
    opacity: 1,
    ClipPath: 'circle(60%)',
    paddingBlock: 'var(--spacing-2-xl)'
  },
  hidden: {
    height: '0px',
    ClipPath: 'circle(10%)',
    opacity: 0,
    paddingBlock: 0
  }
}

const variantsTabletIllu = {
  shown: {
    height: '272px',
    opacity: 1,
    ClipPath: 'circle(60%)',
    paddingBlock: 'var(--spacing-2-xl)'
  },
  hidden: {
    height: '60px',
    ClipPath: 'circle(10%)',
    opacity: 0,
    paddingBlock: 0
  }
}

const transitionMobile = {
  type: 'linear',
  duration: .45
}

// eslint-disable-next-line max-statements
const ServicesBlock: React.FC<ServicesBlockProps> = ({ services }) => {
  // Disable ESLint rule because of complexity of component
  const [x, cycleX] = useCycle(30, 155, -131, -34)
  const [y, cycleY] = useCycle(-143, -53, -111, -68)
  const [scale, cycleScale] = useCycle(.63, .8, .5, .85)
  const [strokeWidth, cycleStrokeWidth] = useCycle(5, 4, 5.5, 3.7)
  const prefersReducedMotion = useMediaQuery('(prefers-reduced-motion: reduce)')
  const isMobile = useMediaQuery('(max-width: 920px)')
  const isSmallScreen = useMediaQuery('(min-width: 562px)')
  const isSmallDesktop = useMediaQuery('(max-width: 1120px)')
  const isLargeDesktop = useMediaQuery('(min-width: 1650px)')

  const [selectedIndex, setSelectedIndex] = useState(0)
  const [isActive, setIsActive] = useState(false)
  const selectedItem = services[selectedIndex]
  const { ref, inView } = useInView({
    threshold: 0
  })

  const colors = {
    '--background': `var(--${selectedItem.pageTheme}-100)`
  } as React.CSSProperties

  const progress = {
    '--background': `var(--${selectedItem.pageTheme}-200)`,
    '--active': `var(--${selectedItem.pageTheme}-500)`
  } as React.CSSProperties

  const noProgress = {
    '--background': 'var(--sweet-liquorice-200)',
    '--active': 'var(--sweet-liquorice-200)'
  } as React.CSSProperties

  useEffect(() => {
    if (!isActive) {
      return
    }

    const timer = setTimeout(() => {
      cycleX()
      cycleY()
      cycleScale()
      cycleStrokeWidth()

      if (selectedIndex === services.length - 1) {
        setSelectedIndex(0)
        return
      }

      setSelectedIndex(selectedIndex + 1)
    }, 5000)

    return () => {
      clearTimeout(timer)
    }
  }, [
    selectedIndex,
    cycleX,
    cycleY,
    cycleScale,
    cycleStrokeWidth,
    isActive,
    services.length
  ])

  useEffect(() => {
    if (inView) {
      setIsActive(true)
    } else {
      setIsActive(false)
    }
  }, [inView])

  function onMouseOver(idx: number) {
    setSelectedIndex(idx)
    setIsActive(false)
    cycleX(idx)
    cycleY(idx)
    cycleScale(idx)
    cycleStrokeWidth(idx)
  }

  function handleMobileInteract(target: React.MouseEvent | React.TouchEvent, idx: number) {
    if (isMobile && selectedIndex !== idx) {
      target.preventDefault()
      setSelectedIndex(idx)
    }
  }

  return (
    <section ref={ref}>

      <Grid cols={12} size="l">
        <Col className={styles.title} start={2}>
          <h2 className={'heading-3-xl'}>Services</h2>
          <ButtonLink
            className={styles['all-services']}
            href="/services"
            icon="arrow"
            iconPosition="right"
            size="l"
            variation="tertiary"
          >View all services</ButtonLink>
        </Col>
      </Grid>

      <div className={styles.block}>

        <Grid className={styles.header} cols={12} size="l">
          <Col className={styles.backgrounds} span={4} start={2} >
            <div className={styles.left}/>
          </Col>
          <Col className={styles.backgrounds} span={isSmallDesktop ? 7 : 5} span1Xl={6} start={isLargeDesktop ? 6 : 7}>
            <div className={styles.right} style={colors}/>
          </Col>
          <Col
            as="ul"
            className={styles.services}
            span={4}
            start={2}
          >
            {services.map(({
              pageTheme,
              slug,
              title,
              overviewExcerpt
            }, idx) => (
              <li
                className={styles.item}
                key={idx}
                onBlur={() => setIsActive(true)}
                onFocus={() => isMobile && onMouseOver(idx)}
                onMouseOut={() => setIsActive(true)}
                onMouseOver={() => onMouseOver(idx)}
                style={isMobile && selectedIndex === idx ? { '--bg': `var(--${pageTheme}-100)` } as React.CSSProperties : {}}
              >
                <Link href={`/services/${slug}`} scroll={false}>
                  <a
                    className={styles.wrapper}
                    onClick={(target) => handleMobileInteract(target, idx)}
                    onTouchStart={(target) => handleMobileInteract(target, idx)}
                  >
                    <AnimatePresence>
                      {isMobile && selectedIndex === idx && (
                        <motion.div
                          animate="shown"
                          className={styles['mobile-image']}
                          exit="hidden"
                          initial="hidden"
                          transition={transitionMobile}
                          variants={isSmallScreen? variantsTabletIllu : variantsMobileIllu}
                        >
                          <img
                            alt={`A gum ball machine representing the ${slug} within Yummygum`}
                            className={`${styles.illu}`}
                            src={`img/services/illu-${slug}-mobile.svg`}
                          />
                        </motion.div>
                      )}
                    </AnimatePresence>
                    <div className={styles.text}>
                      <h3 className="heading-1-xl">{title}</h3>
                      {isMobile
                        ? (
                          <motion.div
                            animate={selectedIndex === idx ? 'shown' : ''}
                            className="body-m"
                            dangerouslySetInnerHTML={{ __html: overviewExcerpt ?? '' }}
                            exit="hidden"
                            initial="hidden"
                            transition={transitionMobile}
                            variants={variantsHiddenText}
                          />
                        )
                        : <div className="body-m" dangerouslySetInnerHTML={{ __html: overviewExcerpt ?? '' }} />}
                    </div>
                    <span className={`${selectedIndex === idx || !isMobile ? styles.active : ''} ${styles.icon}`}>
                      <Icon
                        color="var(--sweet-liquorice-900)"
                        direction={IconRotate.East}
                        name="arrow"
                        size={IconSize.Large}
                      />
                    </span>
                  </a>
                </Link>
                <div className={styles.progress} style={selectedIndex === idx ? progress : noProgress}>
                  <span
                    className={`
                      ${selectedIndex === idx && isActive ? `${styles.start}` : ''}
                      ${selectedIndex === idx && !isActive ? `${styles.stop}` : ''}
                    `}
                  />
                </div>
              </li>
            ))}
          </Col>

          <Col className={styles.area} span={isSmallDesktop ? 7 : 5} span1Xl={6} start={isLargeDesktop ? 6 : 7}>
            <LazyMotion features={domAnimation} strict>
              {prefersReducedMotion
                ? services.map((illu, idx) => (
                  <m.img
                    alt={`${illu.title} illustration`}
                    animate={selectedIndex === idx ? 'shown' : 'hidden'}
                    key={idx}
                    loading="lazy"
                    src={`img/services/illu-${illu}-full.svg`}
                    transition={{ duration: .4 }}
                    variants={variantsReducedMotion}
                  />
                ))
                : (
                  <>
                    <m.svg
                      animate={{
                        x: x,
                        y: y,
                        scale: scale,
                        strokeWidth: strokeWidth
                      }}
                      transition={{
                        type: 'spring',
                        duration: .4,
                        delay: .05
                      }}
                      viewBox="0 0 163 172"
                    >
                      <path d="M132.5 138C132.5 154.443 110.751 169.572 82.1545 169.572C53.558 169.572 31 157.943 31 141.5C31 125.057 46.0954 112.652 82.1545 110.027C110 108 132.5 121.557 132.5 138Z" strokeLinejoin="round" />
                      <path d="M113.105 22.6506C113.105 27 104.5 37.7148 82.1547 37.7148C61 37.7148 52.2042 27 52.2042 22.6506M162.411 89.9628C157 144.5 126.769 169.572 82.8019 169.572C35.0001 169.572 0.000119984 130 3.1925 89.9628C6.38489 49.9256 21.4795 31.2475 48.0142 18.3362C51.4525 9.3286 65.4321 2.58643 82.1547 2.58643C98.4021 2.58643 112.06 8.95084 115.977 17.5738C139.5 20.5 164.5 57 162.411 89.9628Z" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M124.872 42.0659C135.938 54.0611 142.698 70.089 142.698 87.6957C142.698 98.772 141 109.5 135.283 118.439" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M101 14.4689C96.3772 11.9434 89.6472 10.353 82.1547 10.353C74.6623 10.353 67.9322 11.9434 63.3093 14.4689" strokeLinecap="round" strokeLinejoin="round" />
                    </m.svg>

                    <div className={styles['illus-container']}>
                      {
                        services.map((service, idx) => (
                          <m.img
                            alt={`${service.title} illustration`}
                            animate={selectedIndex === idx ? 'shown' : 'hidden'}
                            key={idx}
                            src={`img/services/illu-${service.slug}.svg`}
                            transition={{
                              ease: 'circOut',
                              duration: .6
                            }}
                            variants={variants}
                          />
                        ))
                      }
                    </div>

                  </>
                )}
            </LazyMotion>
          </Col>
        </Grid>
      </div>
    </section>
  )
}

export default ServicesBlock
