export const macOs = {
  attributes: {
    rel: 'icon',
    type: 'image/png',
    href: '/favicons/apple-touch-icon.png'
  },
  content: null,
  tag: 'link'
}

export const appleTouchIcon = {
  attributes: {
    sizes: '180x180',
    rel: 'apple-touch-icon',
    type: 'image/png',
    href: '/favicons/apple-touch-icon.png'
  },
  content: null,
  tag: 'link'
}

export const ico = {
  attributes: {
    rel: 'icon',
    type: 'image/x-icon',
    href: '/favicons/favicon.ico'
  },
  content: null,
  tag: 'link'
}

export const additionalMetaTags = [
  macOs,
  appleTouchIcon,
  ico
]
