import * as React from 'react'
import type { GetStaticProps, NextPage } from 'next'
import { LogoJsonLd } from 'next-seo'

// Components
import SectionHeader from '@/components/organisms/SectionHeader'
import ServicesBlock from '@/components/organisms/ServicesBlock'
import SectionProjects from '@/components/organisms/SectionProjects'
import SectionLogos from '@/components/organisms/SectionLogos'
import SectionBlog from '@/components/organisms/SectionBlog'
import CtaRow from '@/components/organisms/CtaRow'
import MetaHead from '@/components/meta/MetaHead'

// Utils
import { generateBreadcrumbs } from '@/utils'

// Lib
import {
  getBlogPostsByDate,
  getClientLogos,
  getHome,
  getServices
} from '@/lib/api'

// Constants
import { additionalMetaTags } from '@/constants/favicon'

// Styles
import styles from '@/styles/pages/home.module.scss'

const Homepage: NextPage<IHome> = ({ data }) => {
  const metaTags = [
    ...data.home?.seo ?? [],
    ...data.site.favicon,
    ...additionalMetaTags
  ]

  const {
    header,
    cta
  } = data.home!

  const { pages } = data.services

  const {
    title,
    logos
  } = data.clients!

  return (
    <>
      <LogoJsonLd
        logo="https://yummygum.com/img/logo/logo.jpg"
        url="https://yummygum.com"
      />
      <MetaHead metaTags={metaTags} slug="" />
      <main className="home">
        {header.map((content: SectionHeaderProps | null, idx: number) => content === null
          ? null
          : (
            <SectionHeader
              breadcrumb={
                generateBreadcrumbs({
                  title: 'Home',
                  path: ''
                })
              }
              hasBreadcrumb={false}
              key={idx}
              {...content}
              thumbnailDescriptionSize="l"
            />
          ))}

        <ServicesBlock services={pages} />

        <SectionProjects
          description="Recent projects that our team loved working on"
          hideCta
          isSubSection
          // @ts-expect-error This is because of the type generation and null
          projects={data.projects}
          title="Projects"
        />

        <SectionLogos
          hasDivider
          // @ts-expect-error This is because of the type generation and null
          logos={logos}
          title={title ?? ''}
        />

        <SectionBlog
          cardBlogs={data.blogPosts}
          className={styles.blog}
        />

        {/* @ts-expect-error This is because of the type generation and null */}
        <CtaRow
          {...cta}
          className={styles.cta}
        />
      </main>
    </>
  )
}

export const getStaticProps: GetStaticProps = async({ preview }) => {
  const home = await getHome(preview)

  const services = await getServices(preview)

  const { blogPosts } = await getBlogPostsByDate(6, preview)

  const { clients } = await getClientLogos(preview)

  const data: IHome['data'] = {
    ...home,
    blogPosts,
    clients,
    services
  }

  return {
    props: { data }
  }
}

export default Homepage
