import React from 'react'
import Image from 'next/image'

// Components
import Grid, { Col } from '@/components/meta/Grid'
import ButtonLink from '@/components/atoms/ButtonLink'

// Helpers
import { useMediaQuery } from '@/hooks'

// Styling
import styles from './SectionLogos.module.scss'

// Types
export interface SectionLogosProps {
  className?: string,
  title?: string
  logos: RawImageProps[]
  hasDivider?: boolean
}

const SectionLogos: React.FC<SectionLogosProps> = ({
  className = '',
  title,
  logos,
  hasDivider
}) => {
  const isMobile = useMediaQuery('(max-width: 430px)')

  const imageWidth = isMobile ? 200 : 240
  const logoTiming = 3.9

  const listWidth = logos.length * imageWidth || 0
  const timing = Math.round(logos.length * logoTiming) || 0

  return (
    <section className={`
      ${styles.logos}
      ${className}
    `}
    >
      <Grid
        className={styles.logos}
        cols={12}
        gap="0"
        size="m"
      >
        {title && (
          <Col
            className={hasDivider ? `${styles.border}` : ''}
            span={10}
            start={2}
          >
            <h2 className={`heading-1-xl ${styles.title}`}> {title} </h2>
          </Col>
        )}

        <Col span={12}>
          <div className={styles.wrapper}>
            <div className={styles.outer}>
              {[...Array(2)].map((_, listIdx) => (
                <div
                  className={styles.inner}
                  key={listIdx}
                  style={{
                    '--width': `${listWidth}px`,
                    '--time': `${timing}s`
                  } as React.CSSProperties}
                >
                  {logos.map((logo: RawImageProps, logoIdx: number) => {
                    return (
                      <div
                        className={styles.logo}
                        key={`logo-${logoIdx}`}
                      >
                        <Image
                          alt={logo.alt}
                          height={logo.height}
                          src={logo.url}
                          width={logo.width}
                        />
                      </div>
                    )
                  })}
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Grid>

      <div className={styles['button-wrapper']}>
        <ButtonLink
          href="/projects"
          icon="arrow"
          iconPosition="right"
          size="l"
          variation="tertiary"
        >
          Show clients projects
        </ButtonLink>
      </div>
    </section>
  )
}

export default SectionLogos
