import React from 'react'
import Link from 'next/link'

// Components
import Icon from '@/components/atoms/Icon'
import LazyImage from '@/components/atoms/LazyImage'

// Styling
import styles from './CardProject.module.scss'

// Types
import { IconRotate, IconSize } from '@/lib/enums'

const CardProject: React.FC<CardProjectProps> = ({
  title,
  description,
  thumbnail,
  categories,
  keepAspectRatio = true,
  slug,
  thumbnailColor
}) => (
  <Link href={`/projects/${slug}`} scroll={false}>
    <a className="cursor-element">
      <article className={`${styles['card-project']}`}>
        <div className={styles['image-container']}>
          <div className={`
            ${keepAspectRatio ? 'aspect-ratio' : ''}
            ${styles['aspect-ratio']}
            ${styles.wrapper}
          `}
          >
            <LazyImage
              className={styles.thumbnail}
              color={thumbnailColor?.hex}
              // @ts-expect-error this will be fixes with types
              data={thumbnail?.responsiveImage ?? thumbnail}
              imageRootClassName={styles.image}
            />
          </div>
        </div>
        <div className={styles.main}>
          <h2 className={`heading-1-xl ${styles.title}`}>
            {title}
            <span
              className={`${styles.icon}`}
            >
              <Icon
                direction={IconRotate.East}
                name="arrow"
                size={IconSize.Large}
              />
            </span>
          </h2>
          <p className="body-m">{description}</p>
          {categories && categories.length > 0 && (
            <ul className={styles.categories}>
              {/* Exclude categories that contain '-' to remove the sub categories from out Dato */}
              {categories.map((category, idx) => !category.category?.includes('-') && (
                <li className={`body-s ${styles.category}`} key={idx}>
                  {category.category}
                </li>
              ))}
            </ul>
          )}
        </div>
      </article>
    </a>
  </Link>
)

export default CardProject
