import React from 'react'

// Styling
import styles from './CtaRowProjects.module.scss'

// Components
import Button from '@/components/atoms/Button'

// Types
export interface CtaRowCompactProps {
  title: string
  buttonText: string
  onClick?: React.MouseEventHandler<HTMLElement>
}

const CtaRowCompact: React.FC<CtaRowCompactProps> = ({
  title,
  buttonText,
  onClick
}) => (
  <div className={styles.cta}>
    <p className={`body-l ${styles.title}`}>{title}</p>
    <Button
      icon="plus"
      onClick={onClick}
    >
      {buttonText}
    </Button>
  </div>
)

export default CtaRowCompact
